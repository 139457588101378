import { ToastSettings } from '@modules/components/info-toast/type';

export const EXCHANGE_TRANSLATION: Record<string, ToastSettings> = {
  started: {
    title: 'exchange.processing.title',
    subtitle: 'exchange.processing.subtitle',
    icon: 'assets/icons/toast/icon-t-process.svg',
    color: 'blue',
    event_class: '',
  },
  finished: {
    title: 'exchange.ok.title',
    subtitle: 'exchange.ok.subtitle',
    icon: 'assets/icons/toast/icon-t-ok.svg',
    color: 'green',
    event_class: '',
  },
};
